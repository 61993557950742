<template>
  <v-container
    fluid
    class="text-justify"
  >
    <v-row no-gutters justify="center">
      <v-col md="8">
        <h2 class="text-h6 text-center mb-5">
          Impressum für das DV-Verfahren VOKAR
        </h2>
        <h3 class="text-subtitle-1 font-weight-bold mb-2">Dienstinhaber</h3>
        <p>
          Landesamt f&uuml;r Natur, Umwelt und Verbraucherschutz Nordrhein-Westfalen
          (LANUV)<br/>
          Leibnizstr. 10<br/>
          45659 Recklinghausen
        </p>
        <table class="mb-4">
          <tr>
            <td class="pr-2">Telefon:</td>
            <td>+49(0)2361 305-0</td>
          </tr>
          <tr>
            <td>Telefax:</td>
            <td>+49(0)2361 305-3215</td>
          </tr>
          <tr>
            <td>E-Mail:</td>
            <td>
              <v-icon small class="mr-1">mdi-email-outline</v-icon>
              <a href="mailto:poststelle@lanuv.nrw.de">poststelle@lanuv.nrw.de</a>
            </td>
          </tr>
          <tr>
            <td>Ust.-ID:</td>
            <td>DE 126 352 455</td>
          </tr>
        </table>

        <p>
          Das LANUV ist dem Ministerium f&uuml;r Klimaschutz, Umwelt, Landwirtschaft, Natur- und
          Verbraucherschutz des Landes NRW nachgeordnet und wird vertreten durch die Pr&auml;sidentin
          <a href="https://www.lanuv.nrw.de/landesamt/lanuv-stellt-sich-vor/organisation/amtsleitung/"
             target="_blank">Elke&nbsp;Reichert</a>.
        </p>
        <p>
          Der Diensteinhaber ist gem&auml;&szlig; &sect;&nbsp;7 Abs. 1 Telemediengesetz für die mit diesem
          Dienst bereit gestellten Inhalte verantwortlich.
        </p>

        <h3 class="text-subtitle-1 font-weight-bold mb-2">Fachliche Begleitung – Service f&uuml;r Anwenderinnen und Anwender</h3>
        <p>
          Landesamt f&uuml;r Natur, Umwelt und Verbraucherschutz NRW<br/>
          VOKAR-Gesch&auml;ftsstelle, Fachbereich  21<br/>
          Leibnizstra&szlig;e 10<br/>
          45659 Recklinghausen<br/>
          Dienstort: Leibnizstr. 10, 45659 Recklinghausen
        </p>
        <table class="mb-4">
          <tr>
            <td class="pr-2">Telefon:</td>
            <td>+49(0)2361 305 -0</td>
          </tr>
          <tr>
            <td>E-Mail:</td>
            <td>
              <v-icon small class="mr-1">mdi-email-outline</v-icon>
              <a href="mailto:vokar-gs@lanuv.nrw.de">vokar-gs@lanuv.nrw.de</a>
            </td>
          </tr>
        </table>

        <h3 class="text-subtitle-1 font-weight-bold mb-2">Technische Realisierung und Betrieb</h3>
        <p>
          Information und Technik Nordrhein-Westfalen (IT.NRW)<br/>
          - Geoinformationszentrum -<br/>
          Mauerstra&szlig;e 51<br/>
          40476 Düsseldorf
        </p>
        <table class="mb-4">
          <tr>
            <td class="pr-2">Telefon:</td>
            <td>+49(0)211 9449-3333</td>
          </tr>
          <tr>
            <td>E-Mail:</td>
            <td>
              <v-icon small class="mr-1">mdi-email-outline</v-icon>
              <a href="mailto:sd-stoerung@it.nrw.de">sd-stoerung@it.nrw.de</a>
            </td>
          </tr>
          <tr>
            <td>Internet:</td>
            <td><a href="https://www.it.nrw.de">www.it.nrw.de</a></td>
          </tr>
        </table>

        <h3 class="text-subtitle-1 font-weight-bold mb-2">Registrierung der Zugriffe</h3>
        <p>
          Einsichtnahmen aller Nutzerinnen und Nutzer, die &uuml;ber die von der Bundesnotarkammer
          bereitgestellte Authentisierungssoftware SAFE-Notar authentisiert sind und als Notar eine
          Einsichtnahme gem&auml;&szlig; &sect;&nbsp;74 LNatSchG NRW vornehmen, werden dauerhaft gespeichert.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>
